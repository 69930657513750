
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'

@Options({
  components: {
    PagePlugin
  }
})
export default class user extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name)
  }

  expert_apply_status_format(row) {
    if (row.type === 3) {
      return ['待院审', '待校审', '院审不通过', '校审通过', '校审不通过'][
        row.expert_apply_status
      ]
    }
    if (row.type === 2) {
      return ['待审核', '审核通过', '审核不通过'][row.expert_apply_status]
    }
    return ''
  }

  table = {
    page: 1,
    pageSize: 10
  }

  users_all_data = []

  users_total = 0
  users_search_data = {
    name: '',
    phone: '',
    roles: '',
    dept_name: ''
  }

  rule_users_search_data = {}

  users_add_dialog = {
    open: false,
    title: '新增'
  }

  users_add_data = {
    phone: '',
    dept_id: [],
    id_type: 1,
    id_number: '',
    role_ids: [],
    first_name: '',
    dept_name_casual: '',
    last_name: '',
    post_status: 1,
    self_evaluation: '',
    sex: 1,
    birthday: '',
    culture: '',
    post: '',
    post_rank: '',
    age_limit: 0,
    nature: '',
    duty: '',
    social_position: '',
    wechat: '',
    email: '',
    industry_category_ids: [],
    related_industries_ids: [],
    area: [],
    is_verify: 0,
    is_patent: 0,
    patent_code: '',
    patent_file: [] as any[],
    is_market: 0,
    market_code: '',
    market_file: [] as any[],
    work_unit: '',
    profession: [],
    domain: ''
  }

  rule_users_add_data = {
    phone: [
      { required: true, message: '此项不能为空', trigger: 'blur' },
      {
        pattern:
          /(^1[3456789]\d{9}$)|(^([a-zA-Z]|[0-9])(\w|\\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$)/,
        message: '帐号格式不正确',
        trigger: 'blur'
      }
    ],
    dept_id: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    role_ids: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    id_number: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    first_name: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    last_name: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    id_type: [{ required: true, message: '此项不能为空', trigger: 'blur' }]
  }

  users_detail_dialog = {
    open: false,
    title: '详情'
  }

  users_detail_data = {
    name: '',
    phone: '',
    role_ids: [],
    dept_name: '',
    dept_name_casual: '',
    roles: '',
    status: 0,
    first_name: '',
    last_name: '',
    post_status: 1,
    self_evaluation: '',
    sex: 1,
    birthday: '',
    culture: 0,
    post: '',
    post_rank: '',
    age_limit: 0,
    id_number: '',
    nature: 0,
    duty: '',
    social_position: '',
    wechat: '',
    email: '',
    industry_category: '',
    related_industries: '',
    area: [],
    is_verify: 0,
    is_patent: 0,
    patent_code: '',
    patent_file: [] as any[],
    is_market: 0,
    market_code: '',
    market_file: [] as any[],
    avatar: '',
    banks: [] as any[],
    work_unit: '',
    profession: '',
    profession_name: '',
    domain: ''
  }

  users_del_dialog = {
    open: false,
    title: '删除'
  }

  users_del_data = {
    id: 0
  }

  users_edit_dialog = {
    open: false,
    title: '编辑'
  }

  users_edit_data = {
    id: 0,
    name: '',
    phone: '',
    dept_id: [],
    dept_name_casual: '',
    id_type: 1,
    id_number: '',
    role_ids: [],
    first_name: '',
    last_name: '',
    self_evaluation: '',
    sex: 1,
    birthday: '',
    culture: 0,
    post: '',
    post_rank: '',
    age_limit: 0,
    nature: 0,
    duty: '',
    social_position: '',
    wechat: '',
    email: '',
    industry_category_ids: [],
    related_industries_ids: [],
    area: [],
    is_verify: 0,
    is_patent: 0,
    patent_code: '',
    patent_file: [],
    is_market: 0,
    market_code: '',
    market_file: [],
    work_unit: '',
    profession: [],
    domain: ''
  }

  rule_users_edit_data = {
    name: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }],
    phone: [
      { required: true, message: '此项不能为空', trigger: 'blur' },
      {
        pattern:
          /(^1[3456789]\d{9}$)|(^([a-zA-Z]|[0-9])(\w|\\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$)/,
        message: '帐号格式不正确',
        trigger: 'blur'
      }
    ],
    dept_id: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    role_ids: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    id_number: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    id_type: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    first_name: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    last_name: [{ required: true, message: '此项不能为空', trigger: 'blur' }]
  }

  workUtilList = [] // 工作单位
  depts: any[] = []
  roleList: any[] = []
  industries: any[] = []
  industriyList: any[] = []
  addressData: any[] = []
  status_22 = new Map<any, string>([
    [1, '冻结'],
    [0, '正常']
  ])
  post_status_25 = new Map<any, string>([
    [1, '在职'],
    [2, '离职']
  ])
  sex_27 = new Map<any, string>([
    [1, '男'],
    [2, '女']
  ])
  culture_29 = new Map<any, string>([
    [1, '博士'],
    [4, '硕士'],
    [3, '本科'],
    [4, '大专'],
    [5, '大专以下']
  ])
  nature_33 = new Map<any, string>([
    [1, '高校'],
    [2, '科研机构'],
    [3, '科技服务机构'],
    [4, '医院'],
    [5, '社会团体'],
    [6, '其他事业单位'],
    [7, '军队武警'],
    [8, '国企'],
    [9, '民企'],
    [10, '外企'],
    [11, '混合所有制企业'],
    [12, '境外机构'],
    [13, '其他组织']
  ])
  is_verify_43 = new Map<any, string>([
    [1, '是'],
    [0, '否']
  ])
  is_patent_44 = new Map<any, string>([
    [1, '是'],
    [0, '否']
  ])
  is_market_47 = new Map<any, string>([
    [1, '是'],
    [0, '否']
  ])

  pageChange({ currentPage, pageSize }) {
    this.table.page = currentPage
    this.table.pageSize = pageSize
  }

  users_get_all_data(offset = 0, length = 10) {
    const postData = {
      offset: offset || 0,
      length: length || 10,
      name: this.users_search_data.name,
      phone: this.users_search_data.phone,
      dept_name: this.users_search_data.dept_name,
      roles: this.users_search_data.roles
    }
    Api.http_userusers0(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.users_all_data = res.data.data
        this.users_total = res.data.total
      }
    })
  }
  users_add_init(row) {
    this.get_depts(row.id)
    this.get_roleList(row.id)
    this.get_industries(row.id)
    this.get_industriyList(row.id)
    this.get_addressData(row.id)
    this.users_add_dialog.open = true
  }
  users_add_cancle() {
    this.users_add_dialog.open = false
  }
  users_add_ok() {
    this.$refs.ref_users_add_data.validate((valid) => {
      if (valid) {
        const option = [
          {
            label: '居民身份证',
            value: 1,
            reg: /^(^\d{18}$|^\d{17}(\d|X|x))$/
          },
          { label: '台胞证', value: 2, reg: /^(^\d{8})$/ },
          { label: '港澳通行证', value: 3, reg: /^[a-zA-Z][0-9]{9}$/ },
          {
            label: '护照',
            value: 4,
            reg: /^1[45][0-9]{7}$|([P|p|S|s]\d{7}$)|([S|s|G|g]\d{8}$)|([Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8}$)|([H|h|M|m]\d{8,10})$/
          }
        ]
        const typeIndex = option.findIndex(
          (item) => item.value === this.users_add_data.id_type
        )
        const result = option[typeIndex].reg.test(this.users_add_data.id_number)
        if (!result) return this.$message.error('请输入正确的证件号码')
        const postData = {
          phone: this.users_add_data.phone,
          dept_id: this.users_add_data.dept_id,
          id_number: this.users_add_data.id_number,
          role_ids: this.users_add_data.role_ids,
          first_name: this.users_add_data.first_name,
          last_name: this.users_add_data.last_name,
          post_status: this.users_add_data.post_status,
          self_evaluation: this.users_add_data.self_evaluation,
          sex: this.users_add_data.sex,
          birthday: this.users_add_data.birthday,
          id_type: this.users_add_data.id_type,
          culture: this.users_add_data.culture,
          post: this.users_add_data.post,
          post_rank: this.users_add_data.post_rank,
          age_limit: this.users_add_data.age_limit,
          nature: this.users_add_data.nature,
          duty: this.users_add_data.duty,
          social_position: this.users_add_data.social_position,
          wechat: this.users_add_data.wechat,
          email: this.users_add_data.email,
          industry_category_ids: this.users_add_data.industry_category_ids,
          related_industries_ids: this.users_add_data.related_industries_ids,
          area: this.users_add_data.area,
          is_verify: this.users_add_data.is_verify,
          is_patent: this.users_add_data.is_patent,
          patent_code: this.users_add_data.patent_code,
          patent_file: this.users_add_data.patent_file,
          is_market: this.users_add_data.is_market,
          market_code: this.users_add_data.market_code,
          market_file: this.users_add_data.market_file,
          work_unit: this.users_add_data.work_unit,
          profession: this.users_add_data.profession,
          domain: this.users_add_data.domain
        }
        Api.http_userusers1(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.users_add_dialog.open = false
            this.users_get_all_data(
              (this.table.page - 1) * this.table.pageSize,
              this.table.pageSize
            )
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  users_del_init(row) {
    this.users_del_data.id = row.id
    this.users_del_dialog.open = true
  }
  users_del_cancle() {
    this.users_del_dialog.open = false
  }
  users_del_ok() {
    const postData = {
      id: this.users_del_data.id
    }
    Api.http_userusers2(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.users_del_dialog.open = false
        this.users_get_all_data(
          (this.table.page - 1) * this.table.pageSize,
          this.table.pageSize
        )
        this.$message.success(JSON.stringify(res.message))
      }
    })
  }
  users_detail_init(row) {
    this.get_addressData(row.id)

    Api.http_userusersget4({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.users_detail_data.name = res.data.name
        this.users_detail_data.banks = res.data.banks ? res.data.banks : []
        this.users_detail_data.phone = res.data.phone
        this.users_detail_data.role_ids = res.data.role_ids
        this.users_detail_data.avatar = res.data.avatar
        this.users_detail_data.id_number = res.data.id_number
        this.users_detail_data.dept_name = res.data.dept_name
        this.users_detail_data.roles = res.data.roles
        this.users_detail_data.status = res.data.status
        this.users_detail_data.first_name = res.data.first_name
        this.users_detail_data.last_name = res.data.last_name
        this.users_detail_data.post_status = res.data.post_status
        this.users_detail_data.self_evaluation = res.data.self_evaluation
        this.users_detail_data.sex = res.data.sex
        this.users_detail_data.birthday = res.data.birthday
        this.users_detail_data.culture = res.data.culture
        this.users_detail_data.post = res.data.post
        this.users_detail_data.post_rank = res.data.post_rank
        this.users_detail_data.age_limit = res.data.age_limit
        this.users_detail_data.nature = res.data.nature
        this.users_detail_data.duty = res.data.duty
        this.users_detail_data.social_position = res.data.social_position
        this.users_detail_data.wechat = res.data.wechat
        this.users_detail_data.email = res.data.email
        this.users_detail_data.industry_category = res.data.industry_category
        this.users_detail_data.related_industries = res.data.related_industries
        this.users_detail_data.area = res.data.area
        this.users_detail_data.is_verify = res.data.is_verify
        this.users_detail_data.is_patent = res.data.is_patent
        this.users_detail_data.patent_code = res.data.patent_code
        this.users_detail_data.patent_file = res.data.patent_file
        this.users_detail_data.is_market = res.data.is_market
        this.users_detail_data.market_code = res.data.market_code
        this.users_detail_data.market_file = res.data.market_file
        this.users_detail_data.work_unit = res.data.work_unit
        this.users_detail_data.profession = res.data.profession
        this.users_detail_data.profession_name = res.data.profession_name
        this.users_detail_data.domain = res.data.domain
        this.users_detail_data.dept_name_casual = res.data.dept_name_casual
        this.users_detail_dialog.open = true
      }
    })
  }
  users_detail_cancle() {
    this.users_detail_dialog.open = false
  }
  users_detail_ok() {
    this.users_detail_dialog.open = false
  }
  users_edit_init(row) {
    this.get_depts(row.id)
    this.get_roleList(row.id)
    this.get_industries(row.id)
    this.get_industriyList(row.id)
    this.get_addressData(row.id)

    Api.http_userusersget5({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.users_edit_data.id = row.id
        this.users_edit_data.name = res.data.name
        this.users_edit_data.phone = res.data.phone
        this.users_edit_data.dept_id = res.data.dept_id
        this.users_edit_data.id_number = res.data.id_number
        this.users_edit_data.role_ids = res.data.role_ids
        this.users_edit_data.first_name = res.data.first_name
        this.users_edit_data.dept_name_casual = res.data.dept_name_casual
        this.users_edit_data.last_name = res.data.last_name
        this.users_edit_data.self_evaluation = res.data.self_evaluation
        this.users_edit_data.sex = res.data.sex
        this.users_edit_data.birthday = res.data.birthday
        this.users_edit_data.culture = res.data.culture
        this.users_edit_data.post = res.data.post
        this.users_edit_data.post_rank = res.data.post_rank
        this.users_edit_data.age_limit = res.data.age_limit
        this.users_edit_data.nature = res.data.nature
        this.users_edit_data.duty = res.data.duty
        this.users_edit_data.social_position = res.data.social_position
        this.users_edit_data.wechat = res.data.wechat
        this.users_edit_data.email = res.data.email
        this.users_edit_data.industry_category_ids
          = res.data.industry_category_ids
        this.users_edit_data.related_industries_ids
          = res.data.related_industries_ids
        this.users_edit_data.area = res.data.area
        this.users_edit_data.is_verify = res.data.is_verify
        this.users_edit_data.is_patent = res.data.is_patent
        this.users_edit_data.patent_code = res.data.patent_code
        this.users_edit_data.patent_file = res.data.patent_file
        this.users_edit_data.is_market = res.data.is_market
        this.users_edit_data.market_code = res.data.market_code
        this.users_edit_data.id_type = Number(res.data.id_type)
        this.users_edit_data.market_file = res.data.market_file
        this.users_edit_data.work_unit = res.data.work_unit
        this.users_edit_data.profession = res.data.profession
        this.users_edit_data.domain = res.data.domain
        this.users_edit_dialog.open = true
      }
    })
  }
  users_edit_cancle() {
    this.users_edit_dialog.open = false
  }
  users_edit_ok() {
    this.$refs.ref_users_edit_data.validate((valid) => {
      if (valid) {
        const option = [
          {
            label: '居民身份证',
            value: 1,
            reg: /^(^\d{18}$|^\d{17}(\d|X|x))$/
          },
          { label: '台胞证', value: 2, reg: /^(^\d{8})$/ },
          { label: '港澳通行证', value: 3, reg: /^[a-zA-Z][0-9]{9}$/ },
          {
            label: '护照',
            value: 4,
            reg: /^1[45][0-9]{7}$|([P|p|S|s]\d{7}$)|([S|s|G|g]\d{8}$)|([Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8}$)|([H|h|M|m]\d{8,10})$/
          }
        ]
        const typeIndex = option.findIndex(
          (item) => item.value === this.users_edit_data.id_type
        )
        const result = option[typeIndex].reg.test(
          this.users_edit_data.id_number
        )
        if (!result) return this.$message.error('请输入正确的证件编号')
        const postData = {
          id: this.users_edit_data.id,
          name: this.users_edit_data.name,
          phone: this.users_edit_data.phone,
          dept_id: this.users_edit_data.dept_id,
          id_number: this.users_edit_data.id_number,
          id_type: this.users_edit_data.id_type,
          role_ids: this.users_edit_data.role_ids,
          first_name: this.users_edit_data.first_name,
          dept_name_casual: this.users_edit_data.dept_name_casual,
          last_name: this.users_edit_data.last_name,
          self_evaluation: this.users_edit_data.self_evaluation,
          sex: this.users_edit_data.sex,
          birthday: this.users_edit_data.birthday,
          culture: this.users_edit_data.culture,
          post: this.users_edit_data.post,
          post_rank: this.users_edit_data.post_rank,
          age_limit: this.users_edit_data.age_limit,
          nature: this.users_edit_data.nature,
          duty: this.users_edit_data.duty,
          social_position: this.users_edit_data.social_position,
          wechat: this.users_edit_data.wechat,
          email: this.users_edit_data.email,
          industry_category_ids: this.users_edit_data.industry_category_ids,
          related_industries_ids: this.users_edit_data.related_industries_ids,
          area: this.users_edit_data.area,
          is_verify: this.users_edit_data.is_verify,
          is_patent: this.users_edit_data.is_patent,
          patent_code: this.users_edit_data.patent_code,
          patent_file: this.users_edit_data.patent_file,
          is_market: this.users_edit_data.is_market,
          market_code: this.users_edit_data.market_code,
          market_file: this.users_edit_data.market_file,
          work_unit: this.users_edit_data.work_unit,
          profession: this.users_edit_data.profession,
          domain: this.users_edit_data.domain
        }
        Api.http_userusers5(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.users_edit_dialog.open = false
            this.users_get_all_data(
              (this.table.page - 1) * this.table.pageSize,
              this.table.pageSize
            )
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  users_init() {
    this.users_get_all_data(
      (this.table.page - 1) * this.table.pageSize,
      this.table.pageSize
    )
  }
  get_depts(id = -1) {
    Api.http_userusersdepts0({ id: id, offset: 0, length: 999 }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.depts = res.data.data
      }
    })
  }
  get_roleList(id = -1) {
    Api.http_userusersroleList0({ id: id, offset: 0, length: 999 }).then(
      (res) => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.roleList = res.data.data
        }
      }
    )
  }
  get_industries(id = -1) {
    Api.http_userusersindustries0({ id: id, offset: 0, length: 999 }).then(
      (res) => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.industries = res.data.data
        }
      }
    )
  }
  get_industriyList(id = -1) {
    Api.http_userusersindustriyList0({ id: id, offset: 0, length: 999 }).then(
      (res) => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.industriyList = res.data.data
        }
      }
    )
  }
  get_addressData(id = -1) {
    Api.http_userusersaddressData0({ id: id, offset: 0, length: 999 }).then(
      (res) => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.addressData = res.data.data
        }
      }
    )
  }
  // 获取工作单位
  getWorkIndustryList() {
    const postData = {
      offset: 0,
      length: 9999,
      type: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
    }
    Api.http_industryTableA0(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.workUtilList = res.data.data
      }
    })
  }
  created() {
    this.users_init()
    this.getWorkIndustryList()
  }
}
